
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import { IonPage, IonContent, IonList, IonItem, IonThumbnail, IonLabel, IonSkeletonText } from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent, ref } from 'vue';
import { getStoreCategoryProducts, getStoreFeaturedProducts } from "@/services/Api";

export default defineComponent({
  name: 'Category',
  components: {PageHeader, IonPage, IonContent, IonList, IonItem, IonThumbnail, IonLabel, IonSkeletonText},
  props: ['category'],
  setup(props) {
    const products = ref([]);

    // TODO: [CORE] (rdelcampo) Implement Featured page or remove this block
    const isFeaturedPage = props.category === 'destacados';
    const thisCategory = menuCategories.find(thisCategory => thisCategory.slug === props.category) || {
      name: '',
      slug: '',
      id: 0
    };

    if (isFeaturedPage) {
      getStoreFeaturedProducts().then((data: any) => products.value = data);
    } else {
      getStoreCategoryProducts(thisCategory.id).then((data: any) => products.value = data);
    }

    return {
      products
    }
  }
})
