<template>
  <ion-page>
    <page-header :title="category"/>
    <ion-content>
      <ion-list v-if="products.length > 0">
        <ion-item v-for="product in products" button
                  :disabled="product['stock_status'] === 'outofstock'"
                  :router-link="'/carta/' + product['categories'][0]['slug'] + '/' + product['slug']"
                  routerDirection="forward"
                  :key="product['id']">
          <ion-thumbnail class="ion-margin-end">
            <img :src="product['images'][0]['src']" :alt="product['name']"/>
          </ion-thumbnail>
          <ion-label>
            <h2 class="ion-text-wrap">{{ product['name'] }}</h2>
            <p v-if="product['stock_status'] === 'outofstock'">Agotado</p>
            <p v-else>{{ $currency(product['price']) }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-else>
        <ion-item v-for="i in 8" :key="i">
          <ion-thumbnail class="ion-margin-end">
            <ion-skeleton-text animated/>
          </ion-thumbnail>
          <ion-label>
            <h2>
              <ion-skeleton-text animated style="width: 50%; height: 20px"/>
            </h2>
            <p>
              <ion-skeleton-text animated style="width: 10%"/>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import { IonPage, IonContent, IonList, IonItem, IonThumbnail, IonLabel, IonSkeletonText } from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent, ref } from 'vue';
import { getStoreCategoryProducts, getStoreFeaturedProducts } from "@/services/Api";

export default defineComponent({
  name: 'Category',
  components: {PageHeader, IonPage, IonContent, IonList, IonItem, IonThumbnail, IonLabel, IonSkeletonText},
  props: ['category'],
  setup(props) {
    const products = ref([]);

    // TODO: [CORE] (rdelcampo) Implement Featured page or remove this block
    const isFeaturedPage = props.category === 'destacados';
    const thisCategory = menuCategories.find(thisCategory => thisCategory.slug === props.category) || {
      name: '',
      slug: '',
      id: 0
    };

    if (isFeaturedPage) {
      getStoreFeaturedProducts().then((data: any) => products.value = data);
    } else {
      getStoreCategoryProducts(thisCategory.id).then((data: any) => products.value = data);
    }

    return {
      products
    }
  }
})
</script>

<style lang="scss" scoped>
ion-thumbnail {
  width: 100px;
  height: 100px;

  img {
    width: auto !important;
    height: auto !important;
  }
}

.item.active, .item.activated, .item-complex.active .item-content, .item-complex.activated .item-content, .item .item-content.active, .item .item-content.activated {
  border-color: #ccc;
  background-color: #ff0000;
}

ion-item {
  --background-hover: #fff;
  --background-focused: #fff;
  --ripple-color: #fff;
  --background-activated: #fff;
}
</style>
